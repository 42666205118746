<template>
  <div v-if="noticia" class="card">
    <h2 class="card-title text-center mb-2">{{ noticia.titulo }}</h2>
    <div
          class="recibo-preview"
          v-if="noticia.archivo_url"
        >
            <div class="commands-pdf">
                <div class="zoom-command">
                  <button type="button" id="zoom-minus" @click="width -= 50">
                    <icon feather="minus"/>
                  </button>
                  <b style="margin: auto 5px">ZOOM</b>
                  <button type="button" id="zoom-plus" @click="width += 50">
                    <icon feather="plus"/>
                  </button>
                </div>
                <button type="button" class="print-command" id="print-button" @click="imprimir">
                  <b style="margin: auto 5px">IMPRIMIR</b>
                  <icon feather="printer" />
                </button>
            </div>
          <div class="pdf-viewer">
            <pdf
              style="margin: 10px auto"
              v-for="i in numPages"
              :key="i"
              ref="pdf"
              :src="noticia.archivo_url"
              :rotate="rotate"
              :page="i"
              :style="'width:calc(70% + ' + width + 'px );'"
            ></pdf>
          </div>
        </div>
    <div style="padding: 0 5px" v-html="noticia.contenido" v-else></div>
  </div>
</template>
<script>
import {NoticiasServices} from '../services/NoticiasServices';
import pdf from "vue-pdf";
export default {
  name: "MostrarNoticia",
  components: {
    pdf
  },
  data(){
    return {
        noticia: null,
        noticia_uuid: '',
      pdfNoticia: null,
      loadedRatio: 0,
      page: 1,
      width: 0,
      numPages: 0,
      rotate: 0,
    }
  },
  mounted() {
    const noticia_uuid = this.$route.params.uuid;
    NoticiasServices.api.findByUuid(noticia_uuid)
    .then((response) => {
      if(response.success){
        this.noticia = response.noticia
        if (this.noticia.archivo_url) {
          this.pdfNoticia = pdf.createLoadingTask(
            this.noticia.archivo_url
          );
          console.log(this.noticia.archivo_url)
          this.pdfNoticia.promise.then((loadedPDF) => {
            this.numPages = loadedPDF.numPages;
          });
        }
      }
    })
    this.noticia_uuid = noticia_uuid;
  },
  methods: {

    async imprimir(){
      const base64Response = await fetch(this.noticia.archivo_url);
      const blob = await base64Response.blob();
      const url = window.URL.createObjectURL(blob);
      const fileLink = document.createElement("a");
      fileLink.href = url;
      fileLink.target = "_blank";
      fileLink.style.display = "none";
      fileLink.click();
    }
  }
};
</script>
